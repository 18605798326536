<template>
  <WeContainer header="true" v-bind:actions="actions">
    <WeTableSearch v-on:search="onSearch" v-on:clear="onFilterClear">
      <div slot="detail-search">
        <div class="row mb-3">
          <!-- Left -->
          <div class="col-12 col-lg-6">
            <!-- Name -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="product"
                label="Ürün"
                placeholder="Ürün Adı"
                v-model="filter.name"
              />
            </div>
            <!-- Name -->

            <!-- Category -->
            <div class="d-block mb-3">
              <WeRowInput
                form="searchSelect"
                size="sm"
                name="category"
                label="Kategori"
                placeholder="Kategori"
                v-bind:clearable="true"
                v-model="filter.category"
              />
            </div>
            <!-- Category -->

            <!-- Price -->
            <div class="d-block mb-3">
              <div class="form-row align-items-center">
                <div class="col-12 col-lg-3">
                  <label for="price" class="custom-label">Paket Fiyatı</label>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="row align-items-center">
                    <div class="col">
                      <WePriceInput
                        v-bind:small-size="true"
                        class="mb-0"
                        placeholder="0,00"
                        v-model="filter.start_price"
                        ref="startPrice"
                      />
                    </div>
                    <span>-</span>
                    <div class="col">
                      <WePriceInput
                        v-bind:small-size="true"
                        class="mb-0"
                        placeholder="0,00"
                        v-model="filter.end_price"
                        ref="endPrice"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Price -->
          </div>
          <!-- Left -->

          <!-- Right -->
          <div class="col-12 col-lg-6">
            <!-- SKU -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="sku_no"
                label="Stok Kodu"
                placeholder="Stok Kodu"
                v-model="filter.sku_no"
              />
            </div>
            <!-- SKU -->

            <!-- Created At -->
            <div class="d-block mb-3">
              <WeRowInput
                form="date"
                size="sm"
                label="Eklenme Tarihi"
                placeholder="Eklenme Tarihi"
                v-bind:date-range="true"
                v-model="filter.date"
              />
            </div>
            <!-- Created At -->

            <!-- Durum -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="vSelect"
                name="is_active"
                label="Durum"
                placeholder="Durum"
                v-bind:clearable="true"
                v-bind:option-prop="statusOptions"
                v-model="filter.is_active"
              />
            </div>
            <!-- Durum -->
          </div>
          <!-- Right -->
        </div>
      </div>
    </WeTableSearch>
    <WeTable
      v-bind:index="false"
      v-bind:allowSortIndex="false"
      v-bind:data="packages.list"
      v-bind:columns="getColumns"
      v-bind:actions="tableActions"
      v-on:on-action="onAction"
      v-on:on-switch="onSwitch"
      v-bind:ajax="true"
      v-bind:loading="loading"
      v-bind:table-result="tableResult"
      v-on:make-request="updatePackageList"
      ref="packageTable"
    ></WeTable>
  </WeContainer>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "List",
  data() {
    return {
      loading: true,
      copyFilter: {},
      actions: [
        {
          text: "Yeni Paket",
          path: "packages/new",
        },
        {
          text: "Çöp Kutusu",
          class: "btn btn-outline-danger float-right",
          icon: "fas fa-trash",
          path: "packages/trash",
        },
      ],
      tableActions: [
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary ml-2",
          action: "package-edit",
        },
        {
          icon: "fas fa-trash",
          class: "btn-outline-danger ml-2",
          action: "remove",
        },
      ],
      columns: [
        { name: "image", icon: "fas fa-camera", type: "image" },
        { name: "sku_no", th: "Stok Kodu", type: "string" },
        { name: "name", th: "Ürün Adı", type: "string" },
        { name: "category", th: "Kategori", type: "string" },
        { name: "price", th: "Fiyat", type: "currency" },
        { name: "is_active", th: "Durum", type: "boolean" },
      ],
      statusOptions: [
        {
          id: "1",
          name: "Aktif",
        },
        {
          id: "0",
          name: "Pasif",
        },
      ],
      filter: {
        name: null,
        category: null,
        sku_no: null,
        start_price: helper.setPrice(0),
        end_price: helper.setPrice(0),
        is_active: null,
        date: {
          start: null,
          end: null,
        },
      },
      tableResult: {
        item_count: 1,
        current_page: 1,
        total_page: 1,
        total_items: 1,
      },
    };
  },
  methods: {
    ...mapActions("packages", ["getList", "delete", "isActive"]),
    onAction(data) {
      if (data.key == "remove") {
        this.onDelete(data.row, data.index);
      } else if (data.key == "edit") {
        this.onEdit(data.row);
      }
    },
    onEdit(row) {
      this.$router.replace("packages/detail/" + row.id);
    },
    onDelete(row, index) {
      let message = `${row.name} adlı paketi silmek istediğinize emin misiniz ?`;
      this.$swal({
        title: message,
        html: `Bu işlemi <b class="text-danger">Çöp Kutusu</b> bölümünden geri alabilirsiniz.`,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.delete({
            id: row.id,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                this.$toast.success("Paket Başarıyla Silindi");
              } else {
                this.$swal({
                  title: "Paket Silinemedi",
                  text: result.data.message,
                  icon: "error",
                  confirmButtonText: "Tamam",
                });
              }
            },
          });
        }
      });
    },
    checkActiveMarketplaces(data) {
      return data.row.marketplace_list.filter((mp) => mp.is_active).length;
    },
    onSwitch(data) {
      if (
        !data.is_active &&
        this.marketplaceConfig &&
        this.checkActiveMarketplaces(data)
      ) {
        this.$swal({
          title:
            "Uyarı: Paket bağlı olduğu tüm pazaryerlerinde satışa kapatılacaktır!",
          icon: "warning",
          showCancelButton: false,
        })
      }
      this.isActive({
        form: data,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$toast.success("Durum Güncellendi");
          } else {
            this.$toast.error("Durum Güncellenemedi");
          }
        },
        onError: (error) => {
          this.$toast.error("Durum Güncellenemedi");
        },
      });
    },
    onSearch() {
      this.copyFilter = helper.clone(this.filter);
      this.$refs.packageTable.currentPage = 1;
      this.updatePackageList();
    },
    onFilterClear() {
      this.copyFilter = {};
      helper.clearItem(this.filter);
      this.filter.start_price = helper.setPrice(0);
      this.filter.end_price = helper.setPrice(0);

      this.updatePackageList();
    },
    getFilter() {
      let copy = this.copyFilter;

      if (!Object.keys(copy).length) {
        return;
      }

      if (copy.start_price && copy.start_price.hasOwnProperty("unmask")) {
        copy.start_price = copy.start_price.unmask || null;
      }
      if (copy.end_price && copy.end_price.hasOwnProperty("unmask")) {
        copy.end_price = copy.end_price.unmask || null;
      }
      copy.date = [];

      if (this.filter.date && this.filter.date.start && this.filter.date.end) {
        Object.entries(this.filter.date).forEach(([key, value]) => {
          if (value) {
            copy.date.push(value);
          }
        });
      }

      return copy;
    },
    updatePackageList(requestData = null) {
      requestData = requestData || {
        page: 1,
        limit: 25,
      };

      this.loading = true;

      this.getList({
        filter: this.getFilter(),
        table: requestData,
        onSuccess: (result) => {
          helper.copy(result.data, this.tableResult, ["items", "status"]);
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
  },
  computed: {
    ...mapState({
      packages: (state) => state.packages,
      config: (state) => state.session.config,
    }),
    marketplaceConfig() {
      const integrationKey = this.config["site.marketplace_integration"];
      return integrationKey == "1" || integrationKey == 1;
    },
    getColumns() {
      let columns = this.columns;
      if (this.marketplaceConfig) {
        columns.splice(3, 0, {
          name: "marketplace_list",
          th: "Pazaryeri",
          type: "marketplace_list",
        });
      }
      return columns;
    },
  },
  mounted() {
    this.updatePackageList();
  },
};
</script>
